/* eslint-disable camelcase */
import useJwt from '@/auth/jwt/useJwt';
const axios = useJwt.axiosIns;
const actions = {
    /******************************* GET *******************************/

    async getCampaignValidation({ state, dispatch }) {
        return await axios.get(process.env.VUE_APP_APIURL + `/metrics/api/parent-campaigns/`);
    },

    async getChildrenCampaignDetails({ state }) {
        return await axios.get(process.env.VUE_APP_APIURL + '/metrics/api/campaigns/');
    },

    async getAlerts({ state }) {
        const alerts = await axios.get(process.env.VUE_APP_APIURL + '/metrics/alert/');
        return alerts.data;
    },

    async completeMetrics({ state }, campaignCode) {
        const alerts = await axios.post(process.env.VUE_APP_APIURL + '/metrics/api/complete-metrics', { campaignCode });
        return alerts.data;
    },

    async getArchivedCampaigns({ state, dispatch }, { startDate, endDate }) {
        return await axios.get(process.env.VUE_APP_APIURL + `/metrics/api/campaigns/archived`, {
            params: { startDate, endDate },
        });
    },
    async getSupportUser({ state, dispatch }) {
        return await axios.get(process.env.VUE_APP_APIURL + '/user/support/user');
    },

    async getProjectsListandVersions({ state, dispatch }) {
        return await dispatch('genericGet', state.deploy.concat(`/v1/deploy/project-infos`));
    },

    async getProjectsList({ state, dispatch }) {
        return await dispatch('genericGet', state.deploy.concat(`/v1/gitlab/projects`));
    },

    async getPortList({ state, dispatch }) {
        return await dispatch('genericGet', state.deploy.concat('/v1/deploy/ports'));
    },

    async getLogs({ state, dispatch }) {
        return await dispatch('genericGet', state.deploy.concat('/v1/deploy/logs'));
    },

    async getVersionList({ state, dispatch }) {
        return await dispatch('genericGet', state.deploy.concat('/v1/deploy/versions'));
    },

    async getFeaturesAssociatedWithProject({ state, dispatch }, project_id) {
        return await dispatch('genericGet', state.deploy.concat(`/v1/gitlab/projects/${project_id}/branches`));
    },

    async getProjectTags({ state, dispatch }, project_id) {
        return await dispatch('genericGet', state.deploy.concat(`/v1/gitlab/projects/${project_id}/tags`));
    },

    async getCreativeNeededCampaign({ commit, state }) {
        const camps = await axios.get(state.apiBillingiUrl.concat(`/api/v1/campaigns/creative-needed`));
        commit('SET_CREATIVE_CAMPS', camps.data);
    },

    getCreaByBrand({ state, dispatch }, brand) {
        return dispatch('genericGet', state.apiBillingiUrl.concat(`/creas/${brand}`));
    },

    // récupérer tous critères de potentiel proposés par france TV
    getPotentialCriteria({ commit, state }) {
        return axios.get(state.apiBillingiUrl + '/potential/criteria').then((res) => {
            commit('SET_POTENTIAL_CRITERIAS', res.data);
        });
    },

    getAvailableMedias({ commit, state }) {
        return axios.get(state.apiBillingiUrl + '/availableMedias/').then((res) => {
            commit('SET_MEDIA_METRICS', res.data.data);
        });
    },

    getSingleCampaign({ dispatch }, code) {
        return dispatch('genericGet', process.env.VUE_APP_APIURL.concat(`/metrics/api/campaign/single/${code}`));
    },

    getCampaignsByMedia({ state, dispatch }, media) {
        return dispatch('genericGet', state.apiBillingiUrl.concat(`/campaign/${media}/`));
    },

    getFilteredCampaigns({ state, dispatch }, data) {
        return axios.post(state.apiCampaignUrl.concat('/v1/support/filtercampaigns'), data);
    },

    getDspProviders({ state, dispatch }) {
        return dispatch('genericGet', state.apiBillingiUrl.concat(`/dspProviders/`));
    },

    getUsers({ state, dispatch }) {
        return dispatch('genericGet', state.apiUrlAuth.concat('/admin/users/'));
    },

    getCampaignInfos({ state, dispatch }, { code, status }) {
        return dispatch('genericGet', state.apiBApiUrl.concat(`/campaign/api/v1/campaign/infos/${code}/?status=${status}`));
    },

    getBrands({ state, dispatch }) {
        return dispatch('genericGet', state.apiUrlAuth.concat('/admin/brands/'));
    },

    getServices({ state, dispatch }) {
        return dispatch('genericGet', state.apiUrlAuth.concat('/admin/services/'));
    },

    getRights({ state, dispatch }) {
        return dispatch('genericGet', state.apiUrlAuth.concat('/admin/rights/'));
    },

    getUserRights({ state, dispatch }, userUid) {
        return dispatch('genericGet', state.apiUrlAuth.concat(`/admin/users/${userUid}/rights`));
    },

    getUserServices({ state, dispatch }, { userUid, brand }) {
        return dispatch('genericGet', state.apiUrlAuth.concat(`/admin/users/${userUid}/brands/${brand}/services/`));
    },

    getPosInfoByBrand({ state, dispatch }, brand) {
        return dispatch('genericGet', state.apiBillingiUrl.concat(`/posInfo?brand=${brand}`));
    },

    getPosOfInterest({ state, dispatch }, brand) {
        return dispatch('genericGet', state.apiCustomerUrl.concat(`/pointofinterest?brand=${brand}`));
    },

    async getZipcode({ state, dispatch }, payload) {
        return await axios.post(state.apiCustomerUrl.concat(`/areas`), payload);
    },

    getInfoPos({ state, dispatch }, brand) {
        return axios.get(state.apiBillingiUrl.concat(`/posInfo?brand=${brand}`));
    },
    getInvoices({ state, dispatch }, payload) {
        return axios.post(state.apiBillingiUrl.concat(`/api/v1/invoices`), payload);
    },
    downloadInvoice({ state, dispatch }, payload) {
        let headers = {
            responseType: 'blob',
        };
        return axios.post(state.apiBillingiUrl.concat(`/api/v1/downloadInvoice`), payload, headers);
    },

    async downloadFtvPrices({ state, dispatch }, payload) {
        let headers = {
            responseType: 'blob',
        };
        return await axios.get(state.apiBMedia.concat(`/support/multi-pos/media-category/ADS?nb_days=1&brand=${payload.brand}&points_codes=${payload.point_code}`), headers);
    },

    generateInvoice({ state, dispatch }, payload) {
        return axios.post(state.apiBillingiUrl.concat(`/api/v1/generateInvoice`), payload);
    },

    getComparisonPoi({ state, dispatch }, payload) {
        return axios.get(state.apiImportUrl.concat(`/import/poi/${payload.brand}`));
    },

    getComparisonFile({ state }, payload) {
        return axios.post(state.apiImportUrl.concat(`/import/csv-poi/${payload.brand}`), payload.form);
    },

    getAllZipcode({ state, dispatch }, payload) {
        let headers = {
            responseType: 'blob',
        };
        return axios.post(state.apiCustomerUrl.concat(`/zipcode`), payload, headers);
    },

    getSMS({ state, dispatch }) {
        return dispatch('genericGet', state.apiCampaignUrl.concat(`/v1/sms/`));
    },

    getSMSInfos({ state, dispatch }, { code }) {
        return dispatch('genericGet', state.apiCampaignUrl.concat(`/v1/sms/${code}`));
    },

    getSMSTemplate({ state }, { data }) {
        let headers = {
            responseType: 'blob',
        };
        return axios.post(state.apiCampaignUrl + `/v1/sms/template/download/`, data, headers);
    },

    getBannerBrands({ state, dispatch }) {
        return dispatch('genericGet', state.apiBanner.concat(`/api/v1/brands/`));
    },

    getBannerPatterns({ state, dispatch }) {
        return dispatch('genericGet', state.apiBanner.concat(`/api/v1/patterns/`));
    },

    getBanners({ state, dispatch }) {
        return dispatch('genericGet', state.apiBanner.concat(`/api/v1/banners/`));
    },

    getBannerStructures({ state, dispatch }) {
        return dispatch('genericGet', state.apiBanner.concat(`/api/v1/edealer/structures/`));
    },

    getBannerTemplates({ state, dispatch }) {
        return dispatch('genericGet', state.apiBanner.concat(`/api/v1/edealer/`));
    },

    getConfigParams({ state, dispatch }, { brand, field }) {
        return dispatch('genericGet', state.apiBillingiUrl.concat(`/config-params/${field}?brand=${brand}`));
    },

    getCampaign({ state, dispatch }, { campaignCode }) {
        return dispatch('genericGet', state.apiBillingiUrl.concat(`/campaigns/${campaignCode}`));
    },

    async getCampaignDetails({ state }) {
        return (await axios.get(process.env.VUE_APP_APIURL + `/metrics/api/campaigns/${state.currentCampaignCode}/metrics/`)).data;
    },

    async getCreasDatabasedOnBrand({ state }, data) {
        return await axios.get(state.creasUrl + `/admin/themes/?brand=${data}`);
    },

    genericGet(context, url) {
        return axios.get(url);
    },

    getAllSqlRequest({ state }) {
        return axios.get(state.sqlManager + '/all');
    },

    getRoleGeolink({ state }, data) {
        return axios.get(state.sqlManager + `/token/?token=${data}`);
    },

    async getBrandForSqlRequest({ state }) {
        let brands = await axios.get(state.sqlManager + '/brand');
        brands.data.message = [...new Set(brands.data.message)];
        brands.data.value = [...new Set(brands.data.value)];
        return brands;
    },

    getSqlRequestResult({ state }, data) {
        const { code, base } = data;
        return axios.get(state.sqlManager + `/use/?code=${encodeURIComponent(code)}&base=${base}`);
    },
    GetRequestSql({ state }, payload) {
        const { code, details, value, base } = payload;
        return axios.get(state.sqlManager + `/get/?code=${code}&details=${details}&value=${value}&base=${base}`);
    },

    getTradersTemplates({ state }, payload) {
        return axios.get(state.apiCampaignUrl + `/v1/support/templates`);
    },

    getTradersTemplatesByTrigram({ state }, payload) {
        return axios.get(state.apiCampaignUrl + `/v1/support/templates/?media=${payload.tri}`);
    },

    /******************************* POST *******************************/

    addImportData({ state }, payload) {
        return axios.post(state.apiImportUrl.concat(`/import/import-data/${payload.brand}`), payload.form);
    },

    runImportScript({ state }, payload) {
        return axios.post(state.apiImportUrl.concat(`/import/run-script/${payload.brand}`), { email: payload.email });
    },

    async createTraderTemplate({ state }, payload) {
        return await axios.post(state.apiCampaignUrl.concat('/v1/support/templates'), payload);
    },

    copyImport({ state }, brand) {
        return axios.post(state.apiImportUrl.concat(`/import/copy-import/${brand}`));
    },

    truncateTables({ state }, payload) {
        const url = state.apiImportUrl.concat(``);
        return axios.post(state.apiImportUrl.concat(`/import/truncate/${payload.brand}`));
    },

    getDataTarget({ getters }, id) {
        let dataTarget = [];
        if (id) {
            dataTarget = getters.getCriteria
                .filter((target) => target.value === id)
                .map((t) => {
                    return {
                        targetingId: t.value,
                        dataTargetingType: t.dataTargetingType,
                        targetingName: t.text,
                    };
                });
        }
        return dataTarget;
    },

    createPmrCampaigns({ state }, data) {
        const endUrl = data.version === 'v1' ? '/v1/support/pmr-campaigns' : '/v1/support/pmr-campaigns/v2'
        let url = state.apiCampaignUrl.concat(endUrl)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        return axios.post(url, data.form, config);
    },

    generateFinalData({ state }, data) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        return axios.post(state.apiCampaignUrl.concat('/v1/support/finaldata'), data, config);
    },

    deployProject({ state }, data) {
        return axios.post(state.deploy.concat(`/v1/deploy/projects`), data);
    },

    getEvents({ state }, data) {
        const queryParams = [];

        if (data.application) {
            queryParams.push(`application=${data.application}`);
        }
        if (data.id) {
            queryParams.push(`id=${data.id}`);
        }
        if (data.start_date) {
            queryParams.push(`start_date=${data.start_date}`);
        }
        if (data.state) {
            queryParams.push(`state=${data.state}`);
        }
        if (data.end_date) {
            queryParams.push(`end_date=${data.end_date}`);
        }
        if (data.eventCode) {
            queryParams.push(`event_code=${data.eventCode}`);
        }
        const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
        const fullUrl = state.event.concat('/v1/event/' + queryString);
        return axios.get(fullUrl, data);
    },

    // récupérer les potentiels possible en fonction d'un code postal
    async getAvailablePotential({ commit, state, getters, dispatch }, { id, postCodes, startDate, endDate, duration }) {
        let dataTarget = [];
        if (id) {
            dataTarget = getters.getCriteria
                .filter((target) => target.value === id)
                .map((t) => {
                    return {
                        targetingId: t.value,
                        dataTargetingType: t.dataTargetingType,
                        targetingName: t.text,
                    };
                });
        }

        const data = {
            postCodesList: postCodes.split(' ').join('').split(','),
            dataTarget: dataTarget,
            startDate,
            endDate,
            duration,
        };

        try {
            const res = await axios.post(state.apiBillingiUrl + '/potential/potentials', data);
            commit('SET_AVAILABLE_IMPRESSIONS', res.data);
            return res.data;
        } catch (e) {
            console.log(e);
            throw e;
        }
    },

    // récupérer les potentiels possible en fonction d'un code postal
    async getAvailablePotentialTF1({ commit, state, getters, dispatch }, { CodesPostaux, Data, DateDebut, DateFin, Format, SiteGroup, WithBooking }) {
        const data = {
            CodesPostaux: CodesPostaux.split(' ').join('').split(','),
            Data,
            DateDebut,
            DateFin,
            Format,
            SiteGroup,
            WithBooking,
        };
        return axios.post(state.apiBillingiUrl + '/potential/tf1', data).then((res) => {
            commit('SET_AVAILABLE_IMPRESSIONS', res.data);
        });
    },

    async getCreativesBasedOnPubId({ state }, { pubid }) {
        let queryParams = [];

        if (pubid) {
            queryParams.push(`pubid=${pubid}`);
        }
        const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

        const fullUrl = (state.apiBillingiUrl + `/creative`).concat('/' + queryString);
        return axios.get(fullUrl).then((res) => {
            return res.data;
        });
    },

    // récupérer tous critères de potentiel proposés par TF1
    async getPotentialCriteriaTF1({ commit, state }) {
        return axios.get(state.apiBillingiUrl + '/potential/criteria/tf1').then((res) => {
            commit('SET_POTENTIAL_CRITERIAS_TF1', res.data);
        });
    },

    async getFullPotentialByBrand({ commit, state, getters, dispatch }, { id, brand, email, durationByBrand }) {
        const dataTarget = await dispatch('getDataTarget', ({ getters }, id)).then((res) => {
            return res[0].targetingName;
        });

        return axios.post(state.apiBillingiUrl + `/potential/brand/${brand}/potentials/${dataTarget}?email=${email}`, { duration: durationByBrand }).then((res) => {
            return res.data;
        });
    },
    async getFullPotentialByBrandTF1({ commit, state }, { brand, email, dataTF1 }) {
        return axios
            .post(state.apiBillingiUrl + `/potential/brands/${brand}/providers/TF1/potentials`, {
                dataTF1,
                email,
                brand,
            })
            .then((res) => {
                return res.data;
            });
    },

    async mutateCreativeCamps({ commit, state }, updatedCamp) {
        let creativeCamps = state.creativeCamps;
        let campIdx = creativeCamps.findIndex((c) => c.campaign_code == updatedCamp.campaign_code);
        creativeCamps.splice(campIdx, 1, updatedCamp);
        commit('SET_CREATIVE_CAMPS', creativeCamps);
    },

    updateMediaMetricsTemplate({ state }, { code, data }) {
        /* return axios.patch('http://localhost:3400/campaign'.concat(`/api/v1/updateMediaMetrics/${code}`), data); */
        return axios.patch(state.apiCampaignUrl.concat(`/api/v1/updateMediaMetrics/${code}`), data);
    },

    downloadBanner({ state }, data) {
        return axios.post(state.apiBillingiUrl.concat('/banner/download/'), data);
    },

    updateNameAndSubPrice({ state }, { brand, point_code, data }) {
        return axios.patch(state.apiBillingiUrl.concat(`/brand/${brand}/pos/${point_code}/billing/posinfo`), data);
    },

    completeTopTen({ state }, data) {
        return axios.post(state.apiCampaignUrl.concat('/v1/support/campaigns/', data.code, '/topten'));
    },

    syncMetrics({ state }, data) {
        return axios.post(state.apiCampaignUrl.concat('/v1/support/campaigns/', data.code, '/metrics/missing'));
    },

    forceMetrics({ state }, data) {
        return axios.post(state.apiCampaignUrl.concat('/v1/support/campaigns/', data.code, '/metrics/update'));
    },

    fillWithZeroMetrics({ state }, data) {
        return axios.post(state.apiCampaignUrl.concat('/v1/support/campaigns/', data.code, '/metrics/fill-with-zero'));
    },

    async updateParentStatus({ state }, data) {
        return await axios.post(process.env.VUE_APP_APIURL + '/metrics/api/campaigns/update-parent-status', data);
    },

    updateCampaignDate({ state }, data) {
        return axios.post(state.apiBillingiUrl.concat('/campaign/updateDate/'), data);
    },
    addMetrics({ state }, data) {
        return axios.post(state.apiStatistics.concat('/api/v1/metrics/file/'), data);
    },

    addTopten({ state }, data) {
        return axios.post(state.apiStatistics.concat('/api/v1/metrics/topten/'), data);
    },

    updateUserRight({ state }, { userUid, rightInitialDbId, data }) {
        return axios.post(state.apiUrlAuth.concat(`/admin/users/${userUid}/id/${rightInitialDbId}/`), data);
    },

    createUser({ state }, data) {
        return axios.post(state.apiUrlAuth.concat(`/admin/users/`), data);
    },

    linkRight({ state }, { userUid, brand, data }) {
        return axios.post(state.apiUrlAuth.concat(`/admin/users/${userUid}/brands/${brand}/rights/`), data);
    },

    linkBrand({ state }, { userUid, data }) {
        return axios.post(state.apiUrlAuth.concat(`/admin/users/${userUid}/brands/${data.brand}/`), data);
    },

    linkService({ state }, data) {
        return axios.post(state.apiUrlAuth.concat(`/admin/users/${data.uid}/brands/${data.brand}/services/${data.service}`), data);
    },

    createBrand({ state }, data) {
        return axios.post(state.apiUrlAuth.concat(`/admin/brands/`), data);
    },
    createService({ state }, data) {
        return axios.post(state.apiUrlAuth.concat(`/admin/services/`), data);
    },
    createRight({ state }, { serviceName, data }) {
        return axios.post(state.apiUrlAuth.concat(`/admin/services/${serviceName}/rights/`), data);
    },
    createBanner({ state }, data) {
        return axios.post(state.apiBanner.concat(`/api/v1/banners/`), data);
    },
    createSource({ state }, data) {
        return axios.post(state.apiBanner.concat(`/api/v1/patterns/`), data);
    },
    createTemplate({ state }, data) {
        return axios.post(state.apiBanner.concat(`/api/v1/edealer/`), data);
    },
    createStructure({ state }, data) {
        return axios.post(state.apiBanner.concat(`/api/v1/edealer/structures/`), data);
    },
    createBannerBrand({ state }, data) {
        return axios.post(state.apiBanner.concat(`/api/v1/brands/`), data);
    },
    createBug({ state }, data) {
        return axios.post(state.apiSupportUrl.concat(`/api/v1/bug/`), data);
    },

    createProviderCampaign({ state }, { code, formData }) {
        return axios.post(state.apiCampaignUrl.concat(`/cron/create-dsp-campaign/${code}`), formData);
    },

    createFacebookCampaign({ state }, { code, data }) {
        return axios.post(state.apiCampaignUrl.concat(`/cron/create-fb-campaign/${code}`), data);
    },

    getFacebookInfos({ state }, data) {
        const upperCaseBrand = data[0].brand.toUpperCase();
        return axios.get(state.apiCampaignUrl.concat(`/cron/fb-account-info?point_code=${data[0].point_code}&brand=${upperCaseBrand}`));
    },

    getPointCodeReplication({ state }, data) {
        return axios.get(state.apiBillingiUrl.concat(`/replication/${data.brand}/?point_code=${data.point_code}`));
    },

    createCampaignForTtd({ state }, data) {
        return axios.post(state.apiCampaignUrl.concat('/cron/create-dsp-campaign/multiple'), data);
    },

    async associateCreative({ state }, { campCode, data }) {
        return axios.post(state.apiCampaignUrl.concat(`/api/v1/campaigns/${campCode}/creative`), data);
    },

    updateFilterStatus({ commit }, val) {
        commit('UPDATE_STATUS_FILTERS', val);
    },

    updateStatusAlert({ commit }, val) {
        commit('UPDATE_STATUS_ALERT', val);
    },

    updateStatusSubFilter({ commit }, val) {
        commit('UPDATE_STATUS_SUBFILTER', val);
    },

    getBrandsList({ commit }, val) {
        commit('SET_BRANDS_LIST', val);
    },

    getBrandsCampaigns({ commit }, val) {
        commit('SET_BRANDS_CAMPAIGNS', val);
    },

    getBrandsMedias({ commit }, val) {
        commit('SET_BRANDS_MEDIAS', val);
    },
    getBrandsListAlerts({ commit }, val) {
        commit('SET_BRANDS_LIST_ALERTS', val);
    },

    getMediasList({ commit }, val) {
        commit('SET_MEDIAS_LIST', val);
    },

    getMediasFromAlerts({ commit }, val) {
        commit('SET_MEDIAS_ALERTS', val);
    },

    getAlertsTypes({ commit }, val) {
        commit('SET_ALERTS_TYPES', val);
    },

    getGroupedAlerts({ commit }, val) {
        commit('SET_GROUPED_ALERTS', val);
    },

    updateCampaignsList({ commit }, data) {
        commit('UPDATE_CAMPAIGNS_LIST', data);
    },

    updateMediasList({ commit }, data) {
        commit('UPDATE_MEDIAS_LIST', data);
    },

    updateAlertsList({ commit }, data) {
        commit('UPDATE_ALERTS_LIST', data);
    },

    updateGlobalAlerts({ commit }, data) {
        commit('UPDATE_GLOBAL_ALERTS', data);
    },

    updateCurrentPageCampaigns({ commit }, data) {
        commit('UPDATE_CURRENT_PAGE_CAMPAIGNS', data);
    },

    updateGlobalConfigPageCampaigns({ commit }, data) {
        commit('UPDATE_GLOBAL_CONFIG_PAGE_CAMPAIGNS', data);
    },
    resetGlobalConfigPageCampaigns({ commit }) {
        commit('RESET_GLOBAL_CONFIG_PAGE_CAMPAIGNS');
    },

    async addSql({ state }, data) {
        return axios.post(state.sqlManager + '/add', data);
    },

    async modifSQL({ state }, data) {
        return axios.post(state.sqlManager + '/update', data);
    },
    modifiedCampaigns({ commit }, data) {
        commit('SET_MODIFIED_CAMPAIGNS', data);
    },

    modifiedMedias({ commit }, data) {
        commit('SET_MODIFIED_MEDIAS', data);
    },

    /******************************* Patch *******************************/

    //Update campaign creative, code and url, in base and in provider
    updateProviderCampaign({ state }, { code, data }) {
        return axios.patch(state.apiCampaignUrl.concat(`/cron/campaign/${code}/crea`), data);
    },

    updateCampaignDateFtv({ state }, { code, data }) {
        return axios.patch(state.apiCampaignUrl.concat(`/v1/support/campaigns/${code}/update-dates`), data);
    },

    //Update campaign status
    updateCampaignStatus({ state }, { code, data }) {
        return axios.patch(state.apiCampaignUrl.concat(`/api/v1/updateStatus/${code}/`), data);
    },

    //Modify provider id
    changeProviderId({ state }, { code, id }) {
        return axios.put(state.apiBillingiUrl.concat(`/campaign/${code}/provider-id`), { id: id });
    },

    //Archive a child campaign
    removeChildCampaign({ state }, { code, mediaCode }) {
        return axios.patch(state.apiCampaignUrl.concat(`/api/v1/campaign/${code}/media-support/${mediaCode}/remove`));
    },

    //Update a child campaign
    changeChildCampaign({ state }, { params, data }) {
        return axios.patch(state.apiCampaignUrl.concat(`/api/v1/campaign/${params.code}/media-support/${params.mediaCode}/new-media-code/${params.newMediaCode}/change`), data);
    },

    //Transfer a child campaign budget
    transferChildCampaign({ state }, { params, data }) {
        return axios.patch(state.apiCampaignUrl.concat(`/api/v1/campaign/${params.code}/media-support/${params.mediaCode}/new-media-code/${params.newMediaCode}/transfer`), data);
    },

    // Modify or validate From campaign details
    // Status `A payer`
    updateCampaign({ state }, { code, data }) {
        return axios.patch(process.env.VUE_APP_APIURL + `/metrics/campaign/validate/${code}`, { data });
    },

    //Modify or validate From campaign details
    updateCampaignDetails({ state }, { code, data }) {
        return axios.patch(process.env.VUE_APP_APIURL + `/metrics/api/campaign/details/${code}`, { data });
    },

    linkCampaign({ state }, { code, data }) {
        return axios.patch(state.apiBillingiUrl.concat(`/linkcampaign/${code}/`), data);
    },

    deleteAlert({ state, dispatch }, { code }) {
        return axios.delete(process.env.VUE_APP_APIURL + `/metrics/alert/${code}`);
    },

    deleteTradersTemplate({ state }, payload) {
        return axios.delete(state.apiCampaignUrl + `/v1/support/templates/${payload.code}`);
    },

    updateDateAlert({ state, dispatch }, { code, data }) {
        return axios.patch(process.env.VUE_APP_APIURL + `/metrics/alert/${code}/update-date`, data);
    },

    updateAlertResolved({ state, dispatch }, { code, data }) {
        return axios.patch(process.env.VUE_APP_APIURL + `/metrics/alert/${code}/resolved`, data);
    },

    linkCampaigns({ state }, { data }) {
        return axios.patch(state.apiBillingiUrl.concat(`/linkcampaigns/`), data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    updateBanner({ state }, data) {
        return axios.patch(state.apiBanner.concat(`/api/v1/banners/${data.code}`), data);
    },
    updateSource({ state }, data) {
        return axios.patch(state.apiBanner.concat(`/api/v1/patterns/${data.code}`), data);
    },
    updateTemplate({ state }, { code, data }) {
        return axios.patch(state.apiBanner.concat(`/api/v1/edealer/${code}`), data);
    },
    updateStructure({ state }, { code, data }) {
        return axios.patch(state.apiBanner.concat(`/api/v1/edealer/structures/${code}`), data);
    },
    updateBrandBanner({ state }, data) {
        return axios.patch(state.apiBanner.concat(`/api/v1/brands/${data.code}`), data);
    },

    updateTradersTemplate({ state }, payload) {
        return axios.patch(state.apiCampaignUrl + `/v1/support/templates/${payload.code}`, { comment: payload.comment });
    },
    /******************************* Delete *******************************/

    deleteUserRight({ state }, { userUid, rightInitialDbId, rightId }) {
        return axios.delete(state.apiUrlAuth.concat(`/admin/users/${userUid}/rights/${rightId}/id/${rightInitialDbId}`));
    },

    deleteBanner({ state }, code) {
        return axios.delete(state.apiBanner.concat(`/api/v1/banners/${code}`));
    },
    deleteSource({ state }, code) {
        return axios.delete(state.apiBanner.concat(`/api/v1/patterns/${code}`));
    },
    deleteTemplate({ state }, code) {
        return axios.delete(state.apiBanner.concat(`/api/v1/edealer/${code}`));
    },
    deleteBrandBanner({ state }, code) {
        return axios.delete(state.apiBanner.concat(`/api/v1/brands/${code}`));
    },
};

export default actions;
